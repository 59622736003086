import { Box, Flex, Text } from '@chakra-ui/react';
import { ImageWrapper } from 'components/wrapper/imageWrapper';
import React, { FunctionComponent } from 'react';

import { ContactGroupItem as ContactGroupItemModel } from '../../models';

interface ContactGroupItemProps {
  model: ContactGroupItemModel;
}

export const ContactGroupItem: FunctionComponent<ContactGroupItemProps> = ({ model }) => {
  const contact = model.elements?.contact?.linkedItems[0]?.elements;
  return (
    <>
      <Flex
        flexDirection='column'
        color='mountain.base'
        //maxWidth={'280px'}
        //justifyContent='space-between'
        alignItems={{ base: 'center', md: 'flex-start' }}
        textAlign={{ base: 'left', md: 'left' }}
      >
        <Box order={0}>
          <ImageWrapper
            // alt={contact.value}
            imageFrontify={contact?.frontifyImage?.value}
            //   msImage={msImage.value}
            imageSx={{
              mb: { base: 'xs', lg: 's' },
              w: { base: '160px', lg: '200px' },
              h: { base: '160px', lg: '200px' },
            }}
            alt={contact?.displayName?.value}
          />
        </Box>
        <Box style={{ marginLeft: '10px' }}>
          {model.elements.areaOfResponsibility.value && (
            <Text as='h3' mb={{ base: '0', lg: '0' }} textStyle='desktop.body.medium.l'>
              {model.elements.areaOfResponsibility.value}
            </Text>
          )}
          <Text textStyle={model.elements.areaOfResponsibility.value ? 'desktop.body.l' : 'desktop.body.medium.l'}>
            {model.elements.jobTitle.value}
          </Text>
          <Text textStyle='desktop.body.l'>{contact?.displayName?.value}</Text>
        </Box>
      </Flex>
    </>
  );
};
