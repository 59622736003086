import {
  Box,
  ChakraProps,
  Container,
  Flex,
  GridItem,
  ResponsiveValue,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Button } from 'components/base/Button';
import { BodyWrapper } from 'components/wrapper/bodyWrapper';
import cacheData from 'memory-cache';
import { useRouter } from 'next/router';
import { normalize } from 'pages/api/sitemap';
import React, { FC, ReactNode } from 'react';
import { withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { hrefLink } from 'utils/normalization/hrefLink';

import { htmlResolver } from '../../helpers/htmlResolver';
import { getColorScheme } from '../../lib/colorScheme';
import { LinkType } from '../../lib/enums/LinkType';
import { ContentPage, TextModule as TextModel } from '../../models';
import { Link } from '../base/Link';
import { ContactFormModal } from './ContactFormModal';
import { Fact, FactParent } from './Fact';

interface TextModuleProps {
  model: TextModel;
  withGlance?: boolean;
  pageBodyLinks?: any[] | undefined;
}

export const TextModule: FC<TextModuleProps> = ({ model, withGlance, pageBodyLinks }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const translations = withTranslations();
  cacheData.put('translation', translations);

  const router = useRouter();
  cacheData.put('router', router);

  const paddings = model.elements.paddings?.value.map((value) => value.codename);
  const textColorScheme = getColorScheme(
    model.elements.backgroundColor?.value[0]?.codename,
    model.elements.headlineColor?.value[0]?.codename,
  );

  const factColorScheme = getColorScheme(
    model.elements.fact.linkedItems[0]?.elements.backgroundColor.value[0].codename,
    model.elements.fact.linkedItems[0]?.elements.headlineColor.value[0].codename,
  );

  const externalUrl = model.elements.ctaSnippetUrl.value;
  const kontentAiItem = model.elements.ctaSnippetKontentAiUrl.linkedItems[0];
  const fileFrontify = JSON.parse(model.elements.ctaSnippetFrontifyFile.value);
  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;
  // if(model.system.codename == "statiker_certification_campaign_content")
  const [category, setCategory] = React.useState('');
  const [innerLink, setInnerLink] = React.useState([]);

  const getUrl = () => {
    const linkarr: any = [];
    model.elements.body.links.map(async (link) => {
      if (link.codename) {
        const item = (await fetch(`/api/sitemap?codename=${link.codename}&locale=${router.locale}`)
          .then((res) => res.json())
          .catch(() => null)) as ContentPage | null;
        item && setCategory(item?.elements?.category.value[0].codename);
        // category = item?.elements?.category.value[0].codename

        item ? linkarr.push(Object.keys(normalize(item, router.locale, undefined, true, translations))?.[0]) : '';
      }
    });
    return linkarr;
  };
  const setUrl = () => {
    if (fileFrontify) return fileFrontify.downloadUrl as string;
    if (externalUrl) return externalUrl;
    if (kontentAiItem)
      return hrefLink(model, (slug as unknown as string)?.split('/'), false, router.locale, translations);
  };
  React.useEffect(() => {
    const fetchData = async () => {
      const suburl = await getUrl();
      suburl && setInnerLink(suburl);
    };

    fetchData();
  }, [router.locale, router.isReady, translations.locale]);
  //  console.log('innerLink ',innerLink)
  const data = {
    colorScheme: textColorScheme,
    headlineAlignment: model.elements.headlineAlignment.value[0].codename,
    headline: htmlResolver(model.elements.headline)?.[0],
    bodyAlignment: model.elements.bodyAlignment.value[0].codename,
    centerBlock: model.elements.centerBlock.value[0]?.codename === 'yes',
    bodyColumns: Number(model.elements.bodyColumns.value[0].name),
    bodyText: htmlResolver(model.elements.body, model.system.codename, category, innerLink, true),
    ...(model.elements.fact.linkedItems.length &&
      model.elements.headlineAlignment.value[0].codename === 'left' && {
        fact: {
          model: model.elements.fact.linkedItems[0],
          colorScheme: factColorScheme,
        },
      }),
    ...((externalUrl || kontentAiItem || fileFrontify?.downloadUrl) && {
      ctaType: model.elements.ctaSnippetStyle.value[0].codename as keyof typeof ctaField,
      ctaText: model.elements.ctaSnippetCtaText.value,
      ctaUrl: setUrl(),
      ctaOpenInNewTab: model.elements.ctaSnippetOpenIn.value[0].codename === 'new_tab',
    }),
  };

  const ctaField = {
    link: (
      <Link
        href={data.ctaUrl}
        iconName='chevronRightForLink'
        colorScheme={data.colorScheme.accent}
        type={LinkType.SecondaryButton}
        isExternal={data.ctaOpenInNewTab}
        // locale={router.locale}
      >
        {data.ctaText}
      </Link>
    ),
    button: (
      <Button as='a' size='medium' href={data.ctaUrl} target={data.ctaOpenInNewTab ? '_blank' : ''}>
        {data.ctaText}
      </Button>
    ),
    undefined: null,
  };

  const setPaddings = () => {
    const top = paddings.filter((item) => item.includes('top')).join(',');
    const bottom = paddings.filter((item) => item.includes('bottom')).join(',');
    return {
      pt: top.includes('all') ? 0 : top.includes('half') ? { base: 's', lg: 'm' } : { base: 'l', lg: 'xl' },
      pb: bottom.includes('all') ? 0 : bottom.includes('half') ? { base: 's', lg: 'm' } : { base: 'l', lg: 'xl' },
    };
  };

  const Wrapper: FC<{ children: ReactNode; sx: ChakraProps }> = ({ children, sx }) =>
    withGlance ? (
      <Box sx={sx} w={{ '2xl': '' }}>
        {children}
      </Box>
    ) : (
      <Container
        paddingInlineStart={{ base: '0', md: '0', lg: '0' }}
        paddingInlineEnd={{ base: '0', md: '0', lg: '0' }}
        sx={Object.assign(sx, setPaddings())}
        w={data.headlineAlignment === 'center' ? '' : '100vw'}
      >
        {children}
      </Container>
    );

  const formattedBodyText = data.bodyText && data.bodyText.filter((item) => item.body !== '<br>');
  const totalItems = formattedBodyText && formattedBodyText.length;
  return (
    <Box
      id={model.system.codename}
      backgroundColor={data.colorScheme.background}
      w={withGlance ? '' : '100vw'}
      maxW={moduleMaxWidth}
      margin='auto'
    >
      <Wrapper
        sx={{
          textAlign: data.headlineAlignment as CanvasTextAlign,
        }}
      >
        {data.headline && (
          <>
            <Text
              id='subheader'
              as={cacheData.get('homepage') || cacheData.get('hasVideoinHero') ? 'h1' : 'h2'}
              margin={data.centerBlock || data.headlineAlignment === 'center' ? '0 auto' : ''}
              // textStyle={{ base: `mobile.${data.headline.tag}`, lg: `desktop.${data.headline.tag}` }}
              textStyle={
                router.asPath === '/contact-us/polska'
                  ? { base: `mobile.poland_h2`, lg: `desktop.poland_h2` }
                  : { base: `mobile.${data.headline.tag}`, lg: `desktop.${data.headline.tag}` }
              }
              color={data.colorScheme.headline}
              maxWidth={{ lg: 'calc(780px + 160px)' }}
              mb={data.bodyText ? { base: 's', lg: 'm' } : {}}
              px={withGlance ? {} : { base: 'xs', lg: 'l' }}
            >
              {data.headline.body.replace('&nbsp;', '')}
            </Text>
          </>
        )}
        {data.bodyText && (
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            justifyContent={'space-between'}
            display={data.headlineAlignment === 'center' ? 'block' : 'flex'}
            mr={data.fact ? { base: 0, lg: 'l' } : {}}
          >
            <Flex
              maxW='940px'
              w='100%'
              mx={
                data.centerBlock || data.bodyAlignment === 'center' || data.headlineAlignment === 'center'
                  ? 'auto'
                  : '0px'
              }
              {...(data.fact ? { pr: { base: 0, lg: 'l' }, mb: { base: 's', lg: 0 }, mr: { base: 'xs', ld: 0 } } : {})}
              {...(!withGlance ? { px: { base: 'xs', lg: 'l' } } : {})}
            >
              <Box
                w='100%'
                sx={{
                  columnCount: { base: 1, lg: data.bodyColumns === 2 ? 2 : 1 },
                  columnFill: 'balance',
                  columnGap: '80px',
                  orphans: 1,
                  widows: 1,
                }}
              >
                {formattedBodyText.filter(Boolean).map((text, index) => {
                  const isHeadline = text.tag.includes('h');

                  return (
                    <Box
                      key={index}
                      // textStyle={{ base: 'mobile.body.s', md: 'desktop.body.l' }}
                      textStyle={
                        router.asPath === '/contact-us/polska'
                          ? `desktop.body.formatted_polska`
                          : { base: 'mobile.body.s', md: 'desktop.body.l' }
                      }
                      textAlign={data.bodyAlignment as ResponsiveValue<CanvasTextAlign>}
                      w='100%'
                      display='inline-block'
                      sx={{ breakInside: 'avoid' }}
                      _notLast={{
                        pb: {
                          base: 'xs',
                          lg: data.headlineAlignment === 'center' || data.bodyColumns === 1 ? 's' : 'm',
                        },
                      }}
                    >
                      <BodyWrapper
                        tag={text.tag}
                        pageBodyLinks={pageBodyLinks}
                        body={
                          !model.system.codename.includes('ramboll_s_growth_momentum_continues___text_03') &&
                          !model.system.codename.includes('slow_start_in_a_tough_market___macroeconomic_headw') &&
                          !model.system.codename.includes('annual_results__test_table') &&
                          !model.system.codename.includes(
                            'ramboll_continues_to_deliver_sustainable_impact____ed2fc5e',
                          ) &&
                          !model.system.codename.includes('ramboll_continues_to_deliver_sustainable_impact_in') &&
                          !model.system.codename?.includes('annual_result_2023___table')
                            ? text.body.replace('&nbsp;', ' ')
                            : text.body
                        }
                        headingColor={data.colorScheme.headline}
                        isInTextModule={isHeadline}
                        textId={model.system.codename}
                      />
                      {index === totalItems - 1 && data.ctaUrl && (
                        <Flex
                          mt={{ base: 's', md: data.ctaType === 'link' ? 'xs' : 's' }}
                          justifyContent={data.headlineAlignment}
                        >
                          {ctaField[data.ctaType!]}
                        </Flex>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Flex>
            {data.fact && (
              <GridItem maxWidth={{ lg: '420px' }} minW={{ xl: '420px' }}>
                <Fact
                  model={data.fact.model}
                  backgroundColor={data.fact.colorScheme.background}
                  accentColor={data.fact.colorScheme.accent}
                  childOf={FactParent.TEXT_MODULE}
                />
              </GridItem>
            )}
          </Stack>
        )}
        {!data.bodyText && data.ctaUrl && (
          <Flex mt={{ base: 's', md: data.ctaType === 'link' ? 'xs' : 's' }} justifyContent={data.headlineAlignment}>
            {ctaField[data.ctaType!]}
          </Flex>
        )}
        {model.elements.formModalCtaFormModal.linkedItems[0] && model.elements.formModalCtaButtonText.value && (
          <>
            <Flex mt={{ base: 's', md: data.ctaType === 'link' ? 'xs' : 's' }} justifyContent={data.headlineAlignment}>
              <Button
                size='medium'
                aria-controls={model.elements.formModalCtaFormModal.linkedItems[0].system.name}
                onClick={onOpen}
              >
                {model.elements.formModalCtaButtonText.value}
              </Button>
            </Flex>
            <ContactFormModal
              id={`${model.system.id}${model.elements.formModalCtaFormModal.linkedItems[0].system.id}`}
              model={model.elements.formModalCtaFormModal.linkedItems[0]}
              isOpen={isOpen}
              onClose={onClose}
            />
          </>
        )}
      </Wrapper>
    </Box>
  );
};
