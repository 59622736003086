import { Box, Container, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { ProdClient } from 'client/client';
import { BodyWrapper } from 'components/wrapper/bodyWrapper';
import { htmlResolver } from 'helpers/htmlResolver';
import { colorScheme } from 'lib/colorScheme';
import cacheData from 'memory-cache';
import { CustomForm as CustomFormModel } from 'models';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { moduleMaxWidth } from 'themes/overrides';
import { InbuiltFormData } from 'utils/types/formData';

import { InbuiltForm as InbuiltFormModal } from '../../models/content-types/inbuilt_form';
import { FormWrapper } from './FormWrapper';
import { InbuiltCustomForm } from './InbuiltCustomForm';

interface InbuiltFormProps {
  model: InbuiltFormModal;
  onSubmit?: () => void;
}

export const InbuiltForm: FunctionComponent<InbuiltFormProps> = ({ model, onSubmit }) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false);

  const formDataObj = JSON.parse(model.elements.hubspotFormSnippetForm.value);
  const headline = model.elements.headline.value;

  const backgroundColor = model.elements.backgroundColor.value;
  const bgColor = colorScheme[backgroundColor[0].codename as keyof typeof colorScheme].base;
  //  console.log('inbuilt form ',model)
  const textbgColor =
    colorScheme[model.elements.textBesideBackgroundColor?.value[0]?.codename as keyof typeof colorScheme]?.base;
  const textbg = model.elements.textBesideBackground?.value[0]?.codename;
  const title = model.elements.title?.value;
  useEffect(() => {
    if (model.elements.customFormElement.linkedItems.length > 0) {
      const fetchCustomForm = async () => {
        const response = await ProdClient.items<CustomFormModel>()
          .equalsFilter('system.codename', model.elements.customFormElement.linkedItems[0].system.codename)
          .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
          .depthParameter(9)
          .toPromise();
        // console.log('customformdata',response.data.items as CustomFormModel[])
        cacheData.put('customFormData', response.data.items as CustomFormModel[]);
        return response.data.items as CustomFormModel[];
      };
      fetchCustomForm();
    }
    // const genRandomKey = async () => {
    //   console.log(await ecc.randomKey())
    // };

    // genRandomKey();
  }, []);

  const data = {
    formId: formDataObj.FormGUID,
    portalId: formDataObj.PortalID,
    headline: headline,
    backgroundColor: bgColor,
    submittedMessageAlignment: 'center',
    fieldColor: bgColor === 'white' ? 'gray.98' : 'white',
  } as InbuiltFormData;
  const description = htmlResolver(model.elements.summary);
  // console.log('description ',description)
  const addTextBeside = model.elements.addTextBeside.value[0]?.codename;
  // console.log('inbuilt form ', model);
  return (
    <>
      {addTextBeside == 'yes' ? (
        <Box maxW='1440px' pt='117px'>
          <Box
            id={model.system.codename}
            w='100%'
            // pl= '80px'
            // pr='80px'
            //pt='117px'
            //  pt={{ base: '80px', md: '117px' }}
            gap={'10px'}
            display={'flex'}
            bgColor={textbg == 'full' ? textbgColor : 'white'}
            flexDirection={{ base: 'column', md: 'row' }}
            //alignItems="flex-start" justifyItems="space-between"
          >
            <Box
              w={{ base: '100%', md: '45%' }}
              bg={textbgColor}
              pl='80px'
              pt={{ base: '80px', md: textbg == 'full' ? '122px' : '122px' }}
              pb={{ base: '0px', md: '122px' }}
              pr='80px'
            >
              <Text
                w={{ base: '100%', md: '75%' }}
                color={'#273943'}
                /* Desktop/H3 */
                textStyle={'desktop.h3'}
                pb='51px'
              >
                {title}
              </Text>

              <Box>
                {description?.map((text, index) => (
                  <BodyWrapper key={index} sx={index ? { pt: 'xxs' } : { pt: 's' }} tag={text.tag} body={text.body} />
                ))}
              </Box>
            </Box>
            <Box
              w={{ base: '100%', md: '55%' }}
              bgColor={textbg == 'full' ? textbgColor : 'white'}
              pt={{ base: '80px', md: textbg == 'full' ? '122px' : '122px' }}
            >
              {cacheData.get('customFormData') && <InbuiltCustomForm model={cacheData.get('customFormData')[0]} />}
              <Flex
                alignItems={'center'}
                w='100%'
                flexDir='column'
                pb={{ base: '80px', lg: '117px' }}
                pl={{ base: 's', lg: 's' }}
                pr={{ base: 's', lg: '80px' }}
              >
                <Flex gap={{ base: 's', lg: 's' }} flexDir='column' w={{ base: '100%', lg: '100%', xl: '100%' }}>
                  {/* text */}
                  {!hasBeenSubmitted && (
                    <Text
                      //    textAlign={'center'}
                      as='h1'
                      color={
                        model.elements.formHeadlineColor.value[0].codename == 'mountain' ? 'mountain.base' : 'cyan.web'
                      }
                      textStyle={{ base: 'mobile.h4', lg: 'desktop.h4' }}
                    >
                      {data.headline}
                    </Text>
                  )}
                  {/* form */}
                  <FormWrapper
                    data={data}
                    onSubmit={() => {
                      setHasBeenSubmitted(true);
                      if (onSubmit) {
                        onSubmit();
                      }
                    }}
                  />
                </Flex>
              </Flex>
            </Box>
          </Box>
        </Box>
      ) : (
        <VStack
          w='100vw'
          maxW={moduleMaxWidth}
          mx='auto'
          align='stretch'
          id={model.system.codename}
          bg={data.backgroundColor}
          px={{ base: 'xs', md: 'l' }}
          spacing={{
            base: 's',
            md: 'm',
          }}
          mt='10px'
        >
          {cacheData.get('customFormData') && <InbuiltCustomForm model={cacheData.get('customFormData')[0]} />}
          <Flex alignItems={'center'} w='100%' flexDir='column' p={{ base: 's', lg: 'l' }}>
            <Flex gap={{ base: 's', lg: 'm' }} flexDir='column' w={{ base: '100%', lg: '50%', xl: '60%' }}>
              {/* text */}
              {!hasBeenSubmitted && (
                <Heading
                  textAlign={'center'}
                  as='h1'
                  color='#0098EB'
                  textStyle={{ base: 'mobile.h4', lg: 'desktop.h4' }}
                >
                  {data.headline}
                </Heading>
              )}
              {/* form */}
              <FormWrapper
                data={data}
                onSubmit={() => {
                  setHasBeenSubmitted(true);
                  if (onSubmit) {
                    onSubmit();
                  }
                }}
              />
            </Flex>
          </Flex>
        </VStack>
      )}
    </>
  );
};
